import axios from "axios";
import { message } from "antd";

const UserDeactivate = async (
  email,
  password,
  user_id,
  gmail_login,
  token = null
) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/user/deactivate_account/${user_id}`;
  const requestBody = token
    ? { token }
    : gmail_login
    ? { user_id, email }
    : { user_id, email, password: password };

  const data = await axios.post(URL, requestBody);
  if (data.data.success) {
    message.success(data.data.message);
    localStorage.clear();
  } else {
    message.error(data.data.message);
  }
  return data.data;
};

export const DeactivateGoogleAccount = async (id) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/user/deactivate_google_account`;
  const data = await axios.post(URL, {
    user_id: id,
  });
  console.log("Res is :", data);
  if (data?.data?.status) {
    message.success(data.data.message);
    localStorage.clear();
  } else {
    message.error(data.data.message);
  }
  return data.data;
};

export default UserDeactivate;
