import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Navbar from "./SubComponents/Navbar";
import Places from "./SubComponents/Places";
import Saloon from "./SubComponents/Saloon";
import axios from "axios";
import cover from "../assests/cover.png";
import headerimage from "../assests/headerimage.jpg";
import { message } from "antd";
import placesData from "./data/placesData";

const Home = () => {
  const initialstate = {
    Saloons: [],
    States: [],
    stateName: "",
    address: "",
    business_name: "",
  };
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [showModalAddressSearch, setShowModalAddressSearch] = useState(false);
  const [bussinessList, setBussinessList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [FormData, setFormData] = useState(initialstate);
  const { Saloons, address, business_name } = FormData;
  // const onHandleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...FormData,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Renderplaces = placesData.map((place) => (
    <Places key={place._id} place={place} />
  ));
  const RenderSaloon = Saloons.map((saloon) => (
    <Saloon key={saloon._id} saloon={saloon} />
  ));

  const getFeaturedSaloon = () => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/featured_saloon`;

    axios
      .get(link)
      .then((response) => {
        if (response.data.success === true) {
          setFormData({
            ...FormData,
            Saloons: response.data.data,
          });
        }
      })
      .catch((error) => {
        message.error(
          typeof error === "string" ? error : "Something went wrong"
        );
      });
  };
  useEffect(() => {
    getFeaturedSaloon();
    // eslint-disable-next-line
  }, []);

  const bussinessSearch = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
    if (e.target.value.trim() === "") {
      setShowModalSearch(false);
      return;
    } else {
      setShowModalSearch(true);
    }
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/search`;
    axios
      .post(link, {
        business_name: e.target.value,
      })
      .then((response) => {
        console.log(response);
        setBussinessList(response.data.data);
      })

      .catch(function (error) {});
  };

  const addressSearch = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
    if (e.target.value.trim() === "") {
      setShowModalAddressSearch(false);
      return;
    } else {
      setShowModalAddressSearch(true);
    }
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/search/city_state`;
    axios
      .post(link, {
        address: e.target.value,
      })
      .then((response) => {
        console.log(response);
        setAddressList(response.data.data);
      })

      .catch(function (error) {});
  };
  return (
    <>
      <div
        className="p-1 text-center bg-image  headerimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="NavBar">
          <Navbar />
        </div>
        <div className="pt-2 pt-md-5">
          <div className="row maxWidth mxAuto">
            <div className="col-12 mxAuto px-3">
              <div className="mask py-5 my-auto mxAuto ">
                <div className="col-12 mxAutx px-auto">
                  <h2 className="White font44 my-0 text-md-center text-left">
                    You need a Professional, so let's find one for you
                  </h2>
                  <p className="font24 lightGrey text-md-center mb-0 text-left">
                    Spend less time waiting. Pre-book your Professional's
                    appointment to ensure a personalized experience. We can help
                    you find the right Professional in your area!{" "}
                  </p>
                </div>
              </div>

              <div className="col-lg-7 col-md-10 mxAuto searchbox row Radius_50 py-0">
                <div
                  className="col-md col-12"
                  style={{ padding: "10px", position: "relative" }}
                >
                  <input
                    type="text"
                    id="Barbers"
                    className="col-12 ml-auto border-0 searchInput h-100"
                    placeholder="Hairstylist, MUA's, NailTechs..."
                    name="business_name"
                    onChange={(e) => bussinessSearch(e)}
                  ></input>
                  {showModalSearch && (
                    <div
                      className="scrolll"
                      style={{
                        position: "absolute",
                        backgroundColor: "darkgray",
                        width: "100%",
                        minHeight: "75px",
                        maxHeight: "454px",
                        top: "4.5rem",
                        borderRadius: "16px",
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        overflowY: "scroll",
                        zIndex: "100",
                      }}
                    >
                      {bussinessList ? (
                        bussinessList.map((list) => {
                          return (
                            <Link
                              to={`/saloonDetail/${list._id}`}
                              key={list._id}
                              style={{
                                display: "flex",
                                gap: "16px",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={list.images[0]}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "16px",
                                }}
                              />
                              <span style={{ textAlign: "left" }}>
                                {list.business_information.business_name}
                              </span>
                            </Link>
                          );
                        })
                      ) : (
                        <span className="text-center d-flex align-items-center">
                          There is no bussiness List with this name
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="col-md col-12" style={{ padding: "10px" }}>
                  <input
                    type="text"
                    id="State"
                    className="col-12 ml-auto border-0 searchInput h-100"
                    placeholder="California, New York..."
                    name="address"
                    style={{ position: "relative" }}
                    onChange={(e) => addressSearch(e)}
                  ></input>
                  {showModalAddressSearch && (
                    <div
                      className="scrolll"
                      style={{
                        position: "absolute",
                        backgroundColor: "darkgray",
                        width: "100%",
                        minHeight: "75px",
                        maxHeight: "454px",
                        top: "4.5rem",
                        borderRadius: "16px",
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        overflowY: "scroll",
                        zIndex: "100",
                      }}
                    >
                      {addressList ? (
                        addressList.map((list) => {
                          return (
                            <Link
                              to={`/vender/${list.state}`}
                              key={list._id}
                              style={{
                                display: "flex",
                                gap: "16px",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              {/* <img
                                src={list.images[0]}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "16px",
                                }}
                              /> */}
                              <span
                                style={{
                                  textAlign: "left",
                                  textTransform: "uppercase",
                                }}
                              >
                                {list.state}
                              </span>
                            </Link>
                          );
                        })
                      ) : (
                        <span className="text-center d-flex align-items-center">
                          There is no bussiness List with this address!
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {business_name === "" && address === "" ? (
                  <div className="col-md-3 col-12 my-auto px-md-2 py-1">
                    <button
                      type="button"
                      className="Radius_20 Orange SearchButon White border-0"
                    >
                      Search
                    </button>
                  </div>
                ) : (
                  <div className="col-md-3 col-12 my-auto px-md-2 py-1">
                    <NavLink
                      to={{
                        pathname: "/mapViewListing",
                        state: {
                          business_name,
                          address: address.replace(/\s*$/, ""),
                        },
                      }}
                    >
                      <button
                        type="button"
                        className="Radius_20 Orange SearchButon White border-0"
                      >
                        Search
                      </button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-4 maxWidth mxAuto">
        <div className="col-11 mxAuto">
          <div className="row m-0 px-0 paddingTop_5 PadBottom">
            {Renderplaces}
          </div>
          {Saloons.length < 1 ? (
            <></>
          ) : (
            <>
              <h2 className="text-center mt-2">
                Featured Spa's and Professionals
              </h2>
              <div className="row col-12 PadBottom">{RenderSaloon}</div>
            </>
          )}
        </div>
      </div>
      <div
        className="mt-5 text-center bg-image  BackgroundImage img-fluid  "
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="container-fluid  maxWidth mxAuto">
          <div className="align-items-center row coverimg">
            <div className="col-12 col-md-8">
              <h2 className="mxAuto my-auto White font39">
                Want Your Service Book? List Now With Black Booking
              </h2>
            </div>
            <div className="col-12 col-md-4 ">
              <NavLink to="/business">
                <button className="btn-light button mxAuto">List Now</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
