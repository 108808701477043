import React from "react";

import { NavLink } from "react-router-dom";
import { Rate } from "antd";
export default function Saloon(props) {
  console.log(props);

  const { saloon } = props;
  var stars = 0;
  // eslint-disable-next-line
  saloon.reviews.map((review) => {
    stars = stars + review.stars;
  });
  stars = stars / saloon.reviews.length;

  return (
    <>
      <div className="col-sm-6 col-lg-4 col-12 mt-3 ">
        <NavLink to={"/saloonDetail/" + saloon._id} className="Black">
          <div className="d-inline-flex mt-3 mr-0 w-100">
            <img
              src={saloon.images[0]}
              alt="description"
              className="mr-3 saloonimg renderimg"
            ></img>
            <div className="">
              <h6 className="mb-0 mr-3 font17">
                {saloon.business_information.business_name}
              </h6>
              <p className="mb-0 mr-3">{saloon.business_information.city}</p>
              <p className="mb-0 mr-3">{saloon.business_information.State}</p>
              <div className="row d-inline-flex Rate">
                <Rate disabled value={stars} />
              </div>
              <p>{saloon.reviews.length} reviews</p>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}
