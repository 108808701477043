import React, { useContext, useState } from "react";
import logo from "../../assests/lalogo1.png";
import userimage from "../../assests/userimage.svg";
import { Link, NavLink } from "react-router-dom";
import { message } from "antd";
import $ from "jquery";
import { transparentLayerContext } from "../../store/TransparentLayerProvider";
import { useCallback } from "react";

const Navbar = () => {
  const isVendor = localStorage.getItem("type");
  const vendorId = localStorage.getItem("venderId");
  const transparentLayerCtx = useContext(transparentLayerContext);
  const [isOpen, setIsOpen] = useState(false);
  let loggedIn = false;
  if (
    localStorage.getItem("type") === "user" ||
    localStorage.getItem("type") === "vendor"
  ) {
    loggedIn = true;
  }
  const logoutFunction = () => {
    setIsOpen(false);
    loggedIn = false;
    localStorage.clear();
    message.success("Logged Out!");
    // message.success("Logged Out!  will Wait for you.");
  };
  window.onscroll = function (e) {
    if (window.scrollY > 300) {
      $(".NavBar").addClass("fixxed");
    } else {
      if ($(".NavBar").hasClass("fixxed")) {
        $(".NavBar").removeClass("fixxed");
      }
    }
  };
  let id = localStorage.getItem("userId") || localStorage.getItem("venderId");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const connectMenuClickHandler = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      transparentLayerCtx.open(() => {
        setIsOpen(false);
        transparentLayerCtx.close();
      });
    } else {
      setIsOpen(false);
      transparentLayerCtx.close();
    }
  });

  return (
    <>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-11 navBarr maxWidth mxAuto px-0">
            <nav className="navbar navbar-dark px-0 nav navbar-expand-md w-100">
              <NavLink exact className="navbar-brand col-8 col-md-3" to="/">
                <img src={logo} alt={logo}></img>
              </NavLink>
              <div className="col">
                <button
                  className="navbar-toggler ml-auto"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto ">
                  <li className="nav-item ">
                    <NavLink className="nav-link White" to="/">
                      Home <span className="sr-only">(current)</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link White" to="/aboutus">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link White" to="/contactus">
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {isVendor === "vendor" ? (
                      <NavLink
                        className="nav-link White"
                        to={`/bussiness/${vendorId}`}
                      >
                        Edit Your Business
                      </NavLink>
                    ) : (
                      isVendor !== "user" && (
                        <NavLink className="nav-link White" to="/business">
                          List Your Business
                        </NavLink>
                      )
                    )}
                  </li>

                  {loggedIn ? (
                    <>
                      <li className="nav-item m_view">
                        <NavLink className="dropdown-item" to="/account">
                          Account
                        </NavLink>
                      </li>

                      <li className="nav-item m_view">
                        <NavLink className="dropdown-item" to="/careers">
                          Careers
                        </NavLink>
                      </li>

                      <li className="nav-item m_view">
                        <NavLink
                          className="dropdown-item"
                          to={"/notification/" + id}
                        >
                          Notifications
                        </NavLink>
                      </li>

                      <li className="nav-item m_view">
                        <NavLink
                          className="dropdown-item"
                          to="/"
                          onClick={logoutFunction}
                        >
                          Logout
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item m_view">
                        <NavLink className="dropdown-item" to="/careers">
                          Careers
                        </NavLink>
                      </li>

                      <li className="nav-item m_view">
                        <NavLink className="dropdown-item" to="/login">
                          Login
                        </NavLink>
                      </li>

                      <li className="nav-item m_view">
                        <NavLink className="dropdown-item" to="/signup">
                          Signup
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
                <div
                  className="dropdown show rounded D_view"
                  style={{ position: "relative", zIndex: 50 }}
                >
                  <button
                    className="btn btn-outline-none dropdownbtn"
                    type="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    // onClick={connectMenuClickHandler}
                    onClickCapture={connectMenuClickHandler}
                  >
                    <img src={userimage} alt=""></img>
                  </button>

                  {isOpen && loggedIn ? (
                    <>
                      <div
                        className="dropdown-menu dropdownmenu"
                        style={{
                          position: "absolute",
                          display: "block",
                          left: "-7rem",
                        }}
                        aria-labelledby="dropdownMenuLink"
                        id="usernavbar"
                      >
                        <NavLink
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          style={{
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                          }}
                          to="/account"
                        >
                          Account
                        </NavLink>
                        <NavLink
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          to="/careers"
                        >
                          Careers
                        </NavLink>
                        <NavLink
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          to={"/notification/" + id}
                        >
                          Notifications
                        </NavLink>
                        <NavLink
                          className="dropdown-item"
                          style={{ borderRadius: "16px" }}
                          to="/"
                          onClick={logoutFunction}
                        >
                          Logout
                        </NavLink>
                      </div>{" "}
                    </>
                  ) : (
                    isOpen &&
                    !loggedIn && (
                      <>
                        <div
                          className="dropdown-menu dropdownmenu"
                          aria-labelledby="dropdownMenuLink"
                          style={{
                            position: "absolute",
                            display: "block",
                            left: "-7rem",
                          }}
                        >
                          <NavLink
                            onClick={() => setIsOpen(false)}
                            className="dropdown-item"
                            style={{
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: "5px",
                            }}
                            to="/careers"
                          >
                            Careers
                          </NavLink>
                          <Link
                            onClick={() => setIsOpen(false)}
                            className="dropdown-item"
                            to="/login"
                          >
                            Login
                          </Link>
                          <NavLink
                            onClick={() => setIsOpen(false)}
                            className="dropdown-item"
                            style={{ borderRadius: "16px" }}
                            to="/signup"
                          >
                            Signup
                          </NavLink>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
