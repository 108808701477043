import { message } from "antd";
import axios from "axios";
const VendorUpdate = (Data) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/update_vendor_details/` +
    localStorage.getItem("venderId");
  axios
    .put(URL, {
      business_name: Data.business_name,
      website: Data.website,
      city: Data.city,
      State: Data.State,
      address_line_1: Data.address_line_1,
      lat: Data.lat,
      lng: Data.lng,
      payment_type: Data.payment_type,
      services: Data.filteredServices,
      working_hours: Data.working_hours,
      wifi: Data.WiFi,
      instant_booking: Data.Book,
      instagram_link: Data.Instagram,
      facebook_link: Data.Facebook,
      trevel_to_me: Data.trevel_to_me,
    })
    .then((res) => {
      console.log(res);
      if (res.data.success) {
        if (Data.images.length !== 0) {
          let formData = new FormData();
          // eslint-disable-next-line
          Data.images.map((img) => {
            formData.append("images", img);
          });
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/vendor/update_images/` +
                localStorage.getItem("venderId"),
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.success) {
                message.success("Information Updated Successfully");
                window.location =
                  "/businessinfo/" + localStorage.getItem("venderId");
              } else if (response.data.success === false) {
                message.error(response.data.message);
              }
            })

            .catch(function (error) {
              message.error(error);
            });
        } else {
          message.success("Information Updated Successfully");
        }
      } else {
        message.error(res.data.message);
      }
    });
};

export default VendorUpdate;
