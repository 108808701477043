import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AboutUs from "./components/AboutUs";
import BuisnessListingwithoutMap from "./components/BuisnessListingwithoutMap";
import BusinessListing from "./components/BusinessListing";
import Careers from "./components/Careers";
import CodeValidation from "./components/CodeValidatiion";
import ConformDeactivate from "./components/User/ConformDeactivate";
import ContactUs from "./components/ContactUs";
import Covid19 from "./components/Covid19";
import CustomerLogin from "./components/Login/CustomerLogin";
import Fail from "./components/Vendor/Fail";
import Footer from "./components/SubComponents/Footer";
import Home from "./components/Home";
import HowToList from "./components/HowToList";
import LandingScreenforCheck from "./components/LandingScreenforCheck";
import ListYourBusiness from "./components/ListYourBusiness";
import Login from "./components/Login/Login";
import MapViewListing from "./components/MapViewListing";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SaloonDetail from "./components/SaloonDetail";
import Signup from "./components/Signup";
import { Spin } from "antd";
import StatesListing from "./components/StatesListing";
import Success from "./components/Success";
import VendorLogin from "./components/Login/VendorLogin";

const BlogDetails = lazy(() => import("./components/BlogDetails"));
const Blogs = lazy(() => import("./components/Blogs"));
const TermsAndCondition = lazy(() => import("./components/TermsandCondition"));
const UserForgetPassword = lazy(() => import("./components/UserForgetPassword"));
const VendorForgetPassword = lazy(() => import("./components/VendorForgetPassword"));
const ResetPasswordUser = lazy(() => import("./components/ResetPasswordUser"));
const ResetPasswordVendor = lazy(() => import("./components/ResetPasswordVendor"));

const App = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        <Suspense fallback={<Spin size="large" />}>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </Suspense>

        <Suspense fallback={<Spin size="large" />}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/customerlogin" component={CustomerLogin} />
            <Route exact path="/vendorlogin" component={VendorLogin} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/ValidateYourSelf" component={CodeValidation} />
            <Route exact path="/aboutus" component={AboutUs} />
            <Route exact path="/ContactUs" component={ContactUs} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsAndCondition} />
            <Route exact path="/covid19" component={Covid19} />
            <Route exact path="/Business" component={ListYourBusiness} />
            <Route exact path="/blogs" component={Blogs} />
            <Route exact path="/howto" component={HowToList} />
            <Route exact path="/MapViewListing" component={MapViewListing} />
            <Route exact path="/vender/:state" component={StatesListing} />
            <Route exact path="/businesslisting" component={BusinessListing} />
            <Route path="/deactivate_account" component={ConformDeactivate} />
            <Route
              exact
              path="/Saloonlist/:state"
              component={BuisnessListingwithoutMap}
            />
            <Route exact path="/saloonDetail/:_id" component={SaloonDetail} />
            <Route exact path="/careers" component={Careers} />
            <Route
              exact
              path="/userforgetPassword"
              component={UserForgetPassword}
            />
            <Route
              exact
              path="/userforgetPassword"
              component={UserForgetPassword}
            />
            <Route
              exact
              path="/user/forget_password/:_id"
              component={ResetPasswordUser}
            />
            <Route
              exact
              path="/vendor/forget_password/:_id"
              component={ResetPasswordVendor}
            />
            <Route
              exact
              path="/vendorforgetPassword"
              component={VendorForgetPassword}
            />
            <Route exact path="/blog/:_id" component={BlogDetails} />
            <Route exact path="/stripe-account-success" component={Success} />
            <Route exact path="/stripe-account-fail" component={Fail} />
            <LandingScreenforCheck />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </>
      <Footer />
    </div>
  );
};

export default App;
