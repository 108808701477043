import { Modal, Upload, message } from "antd";
import React, { useCallback, useState } from "react";

import { ReactComponent as PlusIcon } from "../../assests/plus.svg";

const ALLOWED_FILE_SIZE = 6; // in MB;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const UploadComponent2 = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = useCallback(({ fileList /* file */ }) => {
    /**
     *
     * @param {number} size
     * @returns
     */
    const fileSizeInBytes = (size) => size / 1024 / 1024; // conversion in MB
    const filterFilesLargerThanSpecifiedSize = fileList.filter(
      (item) => fileSizeInBytes(item.size) <= ALLOWED_FILE_SIZE
    );
    props.imagesSet(filterFilesLargerThanSpecifiedSize);
    setFileList(filterFilesLargerThanSpecifiedSize);
    if (filterFilesLargerThanSpecifiedSize.length < fileList.length) {
      message.error("Some images has been filtered out due to larger than 6MB");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Upload
        accept=".png , .jpeg , .jpg"
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={props.multiple}
        beforeUpload={() => {
          return false;
        }}
      >
        {fileList.length >= props.limit ? null : <PlusIcon />}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadComponent2;
