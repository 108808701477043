import React from "react";
import { NavLink } from "react-router-dom";

export default function Places(props) {
  const { place } = props;
  return (
    <>
      <NavLink
        className="col-6 col-sm-4 col-md-3 paddingTop_5 px-0 d-inline-flex "
        to={{
          pathname: "/vender/" + place.name,
          state: place,
        }}
      >
        <div className="d-inline-flex">
          <img
            src={place.image}
            alt="description"
            className="mr-2 w-25"
            style={{ height: "intrinsic" }}
          />
          <p className="my-auto font16 Black cursor">{place.name}</p>
        </div>
      </NavLink>
    </>
  );
}
