import React from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import VendorUpdate from "../../API/VendorUpdate/VendorUpdate";
import UploadComponent2 from "../SubComponents/UploadComponent2";
import { message, Switch, Input, Spin } from "antd";
import Map from "../Map";
import {
  InstagramFilled,
  FacebookFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Geocode from "react-geocode";
import axios from "axios";
import { NavLink } from "react-router-dom";
import TimeSlot from "../SubComponents/TimeSlot";
class BusinessListinginfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false,
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      password: "",
      payment_type: "",
      business_name: "",
      website: "",
      city: "",
      State: "",
      mapLat: "",
      mapLng: "",
      markerLat: "",
      markerLng: "",
      address_line_1: "",
      service_name: "",
      service_price: "",
      service_time: "",
      filteredServices: [],
      services: [],
      images: [],
      old_images: [],
      working_hours: {
        mon: {
          closed: false,
          time: [],
        },
        tue: {
          closed: false,
          time: [],
        },
        wed: {
          closed: false,
          time: [],
        },
        thu: {
          closed: false,
          time: [],
        },
        fri: {
          closed: false,
          time: [],
        },
        sat: {
          closed: false,
          time: [],
        },
        sun: {
          closed: false,
          time: [],
        },
      },
      WiFi: false,
      Book: false,
      Facebook: "",
      Instagram: "",
      trevel_to_me: false,
      error: "",
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.monTimeUpdate = this.monTimeUpdate.bind(this);
    this.tueTimeUpdate = this.tueTimeUpdate.bind(this);
    this.wedTimeUpdate = this.wedTimeUpdate.bind(this);
    this.thurTimeUpdate = this.thurTimeUpdate.bind(this);
    this.friTimeUpdate = this.friTimeUpdate.bind(this);
    this.satTimeUpdate = this.satTimeUpdate.bind(this);
    this.sunTimeUpdate = this.sunTimeUpdate.bind(this);
    this.imagesSet = this.imagesSet.bind(this);
    this.makeImageArray = this.makeImageArray.bind(this);
  }

  makeImageArray = (img) => {
    this.state.images.push(img);
  };

  imagesSet = (imageArray) => {
    this.setState({ images: imageArray.map((item) => item.originFileObj) });
  };

  onHandleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
      error: "",
    });
  };

  onWiFiChange = (e) => {
    this.setState({
      WiFi: e,
      error: "",
    });
  };

  onBookChange = (e) => {
    this.setState({
      Book: e,
      error: "",
    });
  };

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.addressChange(newLat, newLng);
  };

  onPlaceChange = (place) => {
    const address = place?.formatted_address,
      addressArray = place?.address_components,
      city = this.getCity(addressArray),
      state = this.getState(addressArray),
      latValue = place?.geometry?.location?.lat(),
      lngValue = place?.geometry?.location?.lng();
    this.setState({
      address_line_1: address ? address : "",
      city: city ? city : "",
      State: state ? state : "",
      markerLat: latValue,
      markerLng: lngValue,
      mapLat: latValue,
      mapLng: lngValue,
    });
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray?.length; i++) {
      // eslint-disable-next-line
      if (addressArray[i].types[0] && "locality" == addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let State = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        // eslint-disable-next-line
        if (
          addressArray[i].types[0] &&
          "locality" === addressArray[i].types[0]
        ) {
          State = addressArray[i].long_name;
          return State;
        }
      }
    }
  };

  onTravelChange = (e) => {
    this.setState({
      trevel_to_me: e,
      error: "",
    });
  };

  addressChange = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address_line_1: address ? address : "",
          city: city ? city : "",
          State: state ? state : "",
          markerLat: lat,
          markerLng: lng,
          mapLat: lat,
          mapLng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  monTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  tueTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  wedTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  thurTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  friTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  satTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  sunTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            [e.target.name]: e.target.checked,
          },
        })
      : (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            time: e,
          },
        });
    this.setState({ working_hours: b });
  };

  Service = () => {
    if (
      this.state.service_name !== "" &&
      this.state.service_price !== "" &&
      this.state.service_time !== ""
    ) {
      const random = Math.floor(100_000 + Math.random() * 900_000);
      const newService = {
        serviceid: random,
        service_name: this.state.service_name,
        service_price: this.state.service_price,
        service_time: parseInt(this.state.service_time),
        service_deposit: parseInt(this.state.service_deposit),
      };
      this.setState({
        services: [...this.state.services, newService],
        service_name: "",
        service_price: "",
        service_time: "",
        service_deposit: "",
      });
    }
  };

  onUpdateName = (event) => {
    const id = event.target.id.replace("service_name", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id) {
        array = this.state.services;
        array[index].service_name = event.target.value;
        this.setState({
          services: array,
        });
      }
    });
  };

  onUpdatePrice = (event) => {
    const id1 = event.target.id.replace("service_price", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        if (
          parseInt(array[index].service_deposit) > parseInt(event.target.value)
        ) {
          message.error("Service price cannot be less than deposit fee");
        } else {
          array[index].service_price = event.target.value;
          this.setState({
            services: array,
          });
        }
      }
    });
  };

  onUpdatetime = (event) => {
    const id1 = event.target.id.replace("service_time", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        const value = Math.max(
          1,
          Number(event.target.value.replace(/[^0-9]/gi, ""))
        );
        array[index].service_time = value;
        this.setState({
          services: array,
        });
      }
    });
  };
  onUpdateDeposit = (event) => {
    const id1 = event.target.id.replace("service_deposit", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        if (
          parseInt(array[index].service_price) < parseInt(event.target.value)
        ) {
          message.error("Deposit fee cannot be greator than service price");
        } else {
          const value = Math.max(
            0,
            Number(event.target.value.replace(/[^0-9]/gi, ""))
          );
          array[index].service_deposit = value;
          this.setState({
            services: array,
          });
        }
      }
    });
  };
  componentDidMount() {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/` +
      this.props.match.params._id;
    axios
      .get(link)
      .then((response) => {
        console.log({ response });
        this.setState({
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
          contact: response.data.data.contact,
          password: response.data.data.password,
          business_name: response.data.data.business_information.business_name,
          payment_type: response.data.data.payment_type,
          website: response.data.data.business_information.website,
          city: response.data.data.business_information.city,
          State: response.data.data.business_information.State,
          address_line_1:
            response.data.data.business_information.address_line_1,
          WiFi: response.data.data.wifi,
          Book: response.data.data.instant_booking,
          Facebook: response.data.data.facebook_link,
          Instagram: response.data.data.instagram_link,
          mapLat: response.data.data.lat,
          mapLng: response.data.data.lng,
          working_hours: {
            ...this.state.working_hours,
            mon: response.data.data.working_hours.mon,
            tue: response.data.data.working_hours.tue,
            wed: response.data.data.working_hours.wed,
            thu: response.data.data.working_hours.thu,
            fri: response.data.data.working_hours.fri,
            sat: response.data.data.working_hours.sat,
            sun: response.data.data.working_hours.sun,
          },
          services: response.data.data.services,
          trevel_to_me: response.data.data.trevel_to_me,
          updated: !this.state.updated,
          old_images: response.data.data.images,
        });
      })
      .catch(function (error) {
        message.error("error");
      });
  }

  deleteImage = (imageId) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/delete_images/${this.props.match.params._id}`;
    axios.put(URL, { images: [imageId] }).then((res) => {
      this.setState({
        old_images: res.data.data.images,
      });
    });
  };

  validation = () => {
    if (this.state.business_name !== "") {
      if (this.state.city !== "") {
        if (this.state.State !== "") {
          if (this.state.address_line_1 !== "") {
            // eslint-disable-next-line
            if (this.state.mapLat !== "" || this.state.mapLng !== "") {
              // eslint-disable-next-line
              if (
                (this.state.working_hours.mon?.closed === false &&
                  this.state.working_hours.mon?.time?.length !== 0) ||
                this.state.working_hours.mon?.closed === true
              ) {
                // eslint-disable-next-line
                if (
                  (this.state.working_hours.tue.closed === false &&
                    this.state.working_hours.tue?.time.length !== 0) ||
                  this.state.working_hours.tue?.closed === true
                ) {
                  // eslint-disable-next-line
                  if (
                    (this.state.working_hours.wed?.closed === false &&
                      this.state.working_hours.wed?.time.length !== 0) ||
                    this.state.working_hours.wed?.closed === true
                  ) {
                    // eslint-disable-next-line
                    if (
                      (this.state.working_hours.thu?.closed === false &&
                        this.state.working_hours.thu?.time.length !== 0) ||
                      this.state.working_hours.thu?.closed === true
                    ) {
                      // eslint-disable-next-line
                      if (
                        (this.state.working_hours.fri?.closed === false &&
                          this.state.working_hours.fri?.time.length !== 0) ||
                        this.state.working_hours.fri?.closed === true
                      ) {
                        // eslint-disable-next-line
                        if (
                          (this.state.working_hours.sat?.closed === false &&
                            this.state.working_hours.sat?.time.length !== 0) ||
                          this.state.working_hours.sat?.closed === true
                        ) {
                          // eslint-disable-next-line
                          if (
                            (this.state.working_hours.sun?.closed === false &&
                              this.state.working_hours.sun?.time.length !==
                                0) ||
                            this.state.working_hours.sun?.closed === true
                          ) {
                            if (
                              this.state.service_name === "" &&
                              this.state.service_price === "" &&
                              this.state.service_time === "" &&
                              this.state.services.length > 0
                            ) {
                              if (
                                this.state.images.length !== 1 &&
                                this.state.images.length !== 2 &&
                                this.state.images.length !== 3
                              ) {
                                // eslint-disable-next-line
                                this.state.filteredServices = [];
                                // eslint-disable-next-line
                                this.state.services.map((service) => {
                                  if (
                                    service.service_name !== "" &&
                                    service.service_price !== ""
                                  ) {
                                    this.state.filteredServices.push(service);
                                  } else {
                                    message.error(
                                      "Incomplete service information will Discard the Service"
                                    );
                                  }
                                });
                                VendorUpdate(this.state);
                              } else {
                                this.setState({
                                  error:
                                    "Atleast 4 images are Required or left Empty to Use Older One",
                                });
                                message.error(
                                  "Atleast 4 images are Required or left Empty to Use Older One"
                                );
                              }
                            } else if (
                              this.state.service_name !== "" &&
                              this.state.service_price !== "" &&
                              this.state.service_time === ""
                            ) {
                              this.Service();
                              if (
                                this.state.images.length !== 1 &&
                                this.state.images.length !== 2 &&
                                this.state.images.length !== 3
                              ) {
                                // eslint-disable-next-line
                                this.state.filteredServices = [];
                                // eslint-disable-next-line
                                this.state.services.map((service) => {
                                  if (
                                    service.service_name !== "" &&
                                    service.service_price !== ""
                                  ) {
                                    this.state.filteredServices.push(service);
                                  } else {
                                    message.error(
                                      "Incomplete service information will Discard the Service"
                                    );
                                  }
                                });
                                VendorUpdate(this.state);
                                this.setState({
                                  error: "",
                                });
                              } else {
                                this.setState({
                                  error:
                                    "Atleast 4 images are Required or remove uploaded to use the older one",
                                });
                                message.error(
                                  "Atleast 4 images are Required or remove uploaded to use the older one"
                                );
                              }
                            } else if (
                              (this.state.service_name === "" ||
                                this.state.service_price === "" ||
                                this.state.service_time === "") &&
                              this.state.services.length === 0
                            ) {
                              this.setState({
                                error: "Services are Required",
                              });
                              message.error("Services are Required");
                            }
                          } else {
                            this.setState({
                              error:
                                "Sunday's Time should be Selected Properly",
                            });
                            message.error(
                              "Sunday's Time should be Selected Properly"
                            );
                          }
                        } else {
                          this.setState({
                            error:
                              "Saturday's Time should be Selected Properly",
                          });
                          message.error(
                            "Saturday's Time should be Selected Properly"
                          );
                        }
                      } else {
                        this.setState({
                          error: "Friday's Time should be Selected Properly",
                        });
                        message.error(
                          "Friday's Time should be Selected Properly"
                        );
                      }
                    } else {
                      this.setState({
                        error: "Thursday's Time should be Selected Properly",
                      });
                      message.error(
                        "Thursday's Time should be Selected Properly"
                      );
                    }
                  } else {
                    this.setState({
                      error: "Wednesday's Time should be Selected Properly",
                    });
                    message.error(
                      "Wednesday's Time should be Selected Properly"
                    );
                  }
                } else {
                  this.setState({
                    error: "Tuesday's Time should be Selected Properly",
                  });
                  message.error("Tuesday's Time should be Selected Properly");
                }
              } else {
                this.setState({
                  error: "Monday's Time should be Selected Properly",
                });
                message.error("Monday's Time should be Selected Properly");
              }
            } else if (this.state.mapLat === "" || this.state.mapLng === "") {
              this.setState({
                error: "Select the Correct Location from Map",
              });
              message.error("Select the Correct Location from Map");
            }
          } else if (this.state.address_line_1 === "") {
            this.setState({
              error: "Select the Location from Map",
            });
            message.error("Select the Location from Map");
          }
        } else if (this.state.State === "") {
          this.setState({
            error: "Select the Location from Map",
          });
          message.error("Select the Location from Map");
        }
      } else if (this.state.city === "") {
        this.setState({
          error: "Select the Location from Map",
        });
        message.error("Select the Location from Map");
      }
    } else if (this.state.business_name === "") {
      this.setState({
        error: "Business Name is Required",
      });
      message.error("Business Name is Required");
    }
  };

  render() {
    const RenderMap = this.state.services.map((servicemap) => {
      return (
        <>
          <input
            type="text"
            className=" fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
            name="service_name"
            id={servicemap.serviceid + "service_name"}
            value={servicemap.service_name}
            onChange={this.onUpdateName}
            placeholder="Service Name"
          />
          <input
            type="text"
            className=" fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
            name="service_price"
            id={servicemap.serviceid + "service_price"}
            value={servicemap.service_price}
            onChange={this.onUpdatePrice}
            placeholder="Service Price"
          />
          <input
            type="text"
            className=" fillcolor p-2 col-sm-2 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
            name="service_time"
            id={servicemap.serviceid + "service_time"}
            value={servicemap.service_time}
            onChange={this.onUpdatetime}
            placeholder="time"
          />
          <input
            type="number"
            className=" fillcolor p-2 col-sm-2 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
            name="service_deposit"
            id={servicemap.serviceid + "service_deposit"}
            value={servicemap.service_deposit}
            onChange={this.onUpdateDeposit}
            placeholder="Deposit"
          />
        </>
      );
    });
    if (!this.state.updated) {
      return <Spin size="large" />;
    } else if (this.state.updated) {
      return (
        <>
          <BlackNavbar />
          <div className="maxWidth mxAuto0">
            <div className=" row col-11 mxAuto paddingTop_5">
              <div className=" row mxAuto mt-1 shadow-sm Radius_10 maxWidth">
                <div className="  p-2  ">
                  <div className="row">
                    <h5 className="mt-4 mb-2 Bold mr-auto">
                      Business Information of {this.state.business_name}
                    </h5>
                    <NavLink
                      to={"/featured/" + localStorage.getItem("venderId")}
                    >
                      <button className="btn ml-auto border-0 mb-0 Orange White">
                        Feature Your Salon
                      </button>
                    </NavLink>
                  </div>

                  <form className="pt-2">
                    <div className="row">
                      <input
                        type="text"
                        className="fnameborder fillcolor p-2 col-lg-6 col-md-12 col-12 px-0 py-0  mb-0 bordercolor"
                        name="business_name"
                        placeholder="Business Name"
                        value={this.state.business_name}
                        onChange={this.onHandleChange}
                      />
                      <input
                        type="text"
                        className="lnameborder fillcolor p-2 col-lg-6 col-md-12 col-12 px-0 py-0  mb-0 bordercolor"
                        name="website"
                        placeholder="Business Website"
                        value={this.state.website}
                        onChange={this.onHandleChange}
                      />
                    </div>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        name="address_line_1"
                        className="form-control fillcolor rounded-0"
                        onChange={this.onHandleChange}
                        value={this.state.address_line_1}
                        placeholder="Address"
                      />
                    </div>
                    <Map
                      onChange={this.onPlaceChange}
                      address={this.state.address_line_1}
                      google={this.props.google}
                      center={{
                        lat: this.state.mapLat,
                        lng: this.state.mapLng,
                      }}
                      funt={this.onMarkerDragEnd}
                      city={this.state.city}
                      State={this.state.State}
                      addressChange={this.onHandleChange}
                      getAddress={this.addressChange}
                      height="300px"
                      zoom={14}
                    />
                  </form>

                  <h6 className="pt-4 mt-4 Bold">Payment Type</h6>
                  <div className="col-12 px-0">
                    <ul className="col-12 col-md-8 mx-auto combineButton form RadioToButton d-inline-flex px-0 mb-4">
                      <li className="w-50">
                        <input
                          type="radio"
                          id="full"
                          name="payment_type"
                          value="full"
                          checked={
                            this.state.payment_type === "full" ? true : false
                          }
                          onClick={this.onHandleChange}
                        />
                        <label for="full" className="py-2 mb-0 Bold">
                          Full
                        </label>
                      </li>
                      <li className="w-50">
                        <input
                          type="radio"
                          id="deposit"
                          name="payment_type"
                          value="deposit"
                          checked={
                            this.state.payment_type === "deposit" ? true : false
                          }
                          onClick={this.onHandleChange}
                        />
                        <label for="deposit" className="py-2 mb-0 Bold">
                          Deposit
                        </label>
                      </li>
                      <li className="w-50">
                        <input
                          type="radio"
                          id="both"
                          name="payment_type"
                          value="both"
                          checked={
                            this.state.payment_type === "both" ? true : false
                          }
                          onClick={this.onHandleChange}
                        />
                        <label for="both" className="py-2 mb-0 Bold">
                          Both
                        </label>
                      </li>
                    </ul>
                  </div>

                  <h6 className="mt-5 mb-2 Bold pt-3">
                    Please Select All Opening Time Slots For Each Day
                  </h6>
                  <p>(No worries* No Book Time Slots will Overlap)</p>
                  <div className="">
                    <div className="row">
                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Monday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="closed"
                            checked={this.state.working_hours?.mon?.closed}
                            onChange={(e) => this.monTimeUpdate("checkbox", e)}
                            id="MonClose"
                          />
                          <label className="form-check-label" for="MonClose">
                            Close{" "}
                          </label>
                        </div>
                      </div>
                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.mon?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.mon?.time}
                            handleTimeChange={this.monTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Tuesday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="closed"
                            checked={this.state.working_hours?.tue?.closed}
                            id="tueClose"
                            onChange={(e) => this.tueTimeUpdate("checkbox", e)}
                          />
                          <label className="form-check-label" for="tueClose">
                            Close
                          </label>
                        </div>
                      </div>
                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.tue?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.tue?.time}
                            handleTimeChange={this.tueTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Wednesday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="closed"
                            id="wedClose"
                            checked={this.state.working_hours?.wed?.closed}
                            onChange={(e) => this.wedTimeUpdate("checkbox", e)}
                          />
                          <label className="form-check-label" for="wedClose">
                            Close
                          </label>
                        </div>
                      </div>

                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.wed?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.wed?.time}
                            handleTimeChange={this.wedTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Thursday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="closed"
                            checked={this.state.working_hours?.thu?.closed}
                            onChange={(e) => this.thurTimeUpdate("checkbox", e)}
                            id="thurClose"
                          />
                          <label className="form-check-label" for="thurClose">
                            Close{" "}
                          </label>
                        </div>
                      </div>

                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.thu?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.thu?.time}
                            handleTimeChange={this.thurTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Friday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.working_hours?.fri?.closed}
                            name="closed"
                            onChange={(e) => this.friTimeUpdate("checkbox", e)}
                            id="FriClose"
                          />
                          <label className="form-check-label" for="FriClose">
                            Close
                          </label>
                        </div>
                      </div>

                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.fri?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.fri?.time}
                            handleTimeChange={this.friTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Saturday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.working_hours?.sat?.closed}
                            name="closed"
                            onChange={(e) => this.satTimeUpdate("checkbox", e)}
                            id="satClose"
                          />
                          <label className="form-check-label" for="satClose">
                            Close
                          </label>
                        </div>
                      </div>

                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.sat?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.sat?.time}
                            handleTimeChange={this.satTimeUpdate}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 mt-2">
                        <h6 className="mb-0">Sunday</h6>
                      </div>

                      <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                        <div className="form-check mr-auto mx-sm-auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.working_hours?.sun?.closed}
                            name="closed"
                            onChange={(e) => this.sunTimeUpdate("checkbox", e)}
                            id="sunClose"
                          />
                          <label className="form-check-label" for="sunClose">
                            Close
                          </label>
                        </div>
                      </div>
                      <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                        <div
                          className={` ${
                            this.state.working_hours?.sun?.closed === true
                              ? "d-none"
                              : "show"
                          }`}
                        >
                          <TimeSlot
                            selected={this.state.working_hours?.sun?.time}
                            handleTimeChange={this.sunTimeUpdate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Add Service Menu Form */}
                  <h6 className="mt-4 mb-2 Bold">Add Service Menu</h6>

                  <form>
                    <div className="row">
                      {RenderMap}
                      <input
                        type="text"
                        className="fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
                        name="service_name"
                        placeholder="Service Name"
                        value={this.state.service_name}
                        onChange={this.onHandleChange}
                      />
                      <input
                        type="text"
                        className="fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
                        name="service_price"
                        placeholder="Service Price"
                        value={this.state.service_price}
                        onChange={this.onHandleChange}
                      />
                      <input
                        type="text"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Service time Required"
                        className="fillcolor p-2 col-sm-2 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
                        placeholder="time"
                        value={this.state.service_time}
                        name="service_time"
                        onChange={this.onHandleChange}
                      />
                      <input
                        type="number"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Service Deposit Required"
                        className="fillcolor p-2 col-sm-2 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
                        placeholder="deposit"
                        value={this.state.service_deposit}
                        name="service_deposit"
                        onChange={this.onHandleChange}
                      />
                    </div>
                    <div className=" col-12">
                      <button
                        type="button"
                        className="btn rounded-circle d-block mxAuto Orange White mt-4 "
                        onClick={this.Service}
                      >
                        +
                      </button>
                    </div>
                  </form>
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                      marginTop: 10,
                    }}
                  >
                    {this.state.old_images?.map((image) => {
                      return (
                        <div
                          style={{
                            position: "relative",
                            width: "fit-content",
                          }}
                          key={image}
                        >
                          <img
                            src={image}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid #EA9222",
                              borderRadius: "8px",
                              objectFit: "cover",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              fontSize: "1.5rem",
                              top: "-12px",
                              right: "0",
                              cursor: "pointer",
                              color: "#EA9222",
                            }}
                            onClick={() => this.deleteImage(image)}
                          >
                            <CloseCircleOutlined />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <h6 className="mt-4 mb-2 Bold">Upload Images</h6>
                    <p className="text-danger">
                      If you want to upload the Images then Upload all the
                      Images Again else left it empty
                    </p>
                    <UploadComponent2
                      limit={7}
                      imagesSet={this.imagesSet}
                      multiple
                    />
                  </div>
                  <h6 className="mt-4 mb-2 Bold">Amenities</h6>
                  <div className="form-group row">
                    <label for="Book" className="font16 col-8">
                      Instant Booking
                    </label>
                    <div className="col-4">
                      <Switch
                        id="Book"
                        unCheckedChildren="OFF"
                        checkedChildren="ON"
                        checked={this.state.Book}
                        onChange={this.onBookChange}
                        className="ml-auto"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="WiFI" className="font16 col-8">
                      Wi-Fi
                    </label>
                    <div className="col-4">
                      <Switch
                        id="WiFi"
                        unCheckedChildren="OFF"
                        checkedChildren="ON"
                        checked={this.state.WiFi}
                        onChange={this.onWiFiChange}
                        className="ml-auto"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label for="Travel" className="font16 col-8">
                      Offer Services at door Step
                    </label>
                    <div className="col-4 my-auto">
                      <Switch
                        id="Travel"
                        unCheckedChildren="OFF"
                        checkedChildren="ON"
                        checked={this.state.trevel_to_me}
                        onChange={this.onTravelChange}
                        className="ml-auto"
                      />
                    </div>
                  </div>
                  <h6 className="mt-4 mb-2 Bold">Social Links</h6>
                  <div className="form-group">
                    <Input
                      size="large"
                      placeholder="Facebook Link"
                      name="Facebook"
                      value={this.state.Facebook}
                      onChange={this.onHandleChange}
                      prefix={<FacebookFilled />}
                    />
                    <Input
                      size="large"
                      placeholder="Instagram Link"
                      name="Instagram"
                      value={this.state.Instagram}
                      onChange={this.onHandleChange}
                      prefix={<InstagramFilled />}
                    />
                  </div>
                  <button
                    className="btn White Orange d-block col-md-5 col-12 p-2 Radius_10 mxAuto"
                    type="button"
                    onClick={this.validation}
                  >
                    Update
                  </button>
                  <p
                    className="text-danger text-center mb-0 py-2"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    {" "}
                    {this.state.error}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default BusinessListinginfo;
